import { typedjson } from 'remix-typedjson'
import { z } from 'zod'
import { requireUserId } from './auth.server.ts'
import { User } from './db.server.ts'
import { useOptionalUser } from './user.ts'

export const Role = z.enum([
	'analytics',
	'coach',
	'exec',
	'health',
	'scout',
	'no-role',
])
export type Role = z.infer<typeof Role>

export async function requireUserWithRole(request: Request, role: Role[]) {
	const userId = await requireUserId(request)

	const user = await User.findOne({
		where: { email: userId, role },
	})

	if (!user) {
		throw typedjson('Not Found', { status: 404 })
	}

	return user.email
}

export function useHasRole(roles: Role[]) {
	const user = useOptionalUser()
	if (!user) return false
	return roles.some(role => user.role === role)
}
